import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '~/components/Layout';
import { Article } from './style';

const BlogPostTemplate: React.FC<PageProps<GatsbyTypes.BlogPageQuery>> = ({
  data,
}) => {
  const { markdownRemark } = data;
  return (
    <Layout>
      <Article>
        {markdownRemark?.frontmatter?.date && (
          <caption>{markdownRemark.frontmatter.date}</caption>
        )}
        {markdownRemark?.frontmatter?.title && (
          <h1>{markdownRemark.frontmatter.title}</h1>
        )}

        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: markdownRemark?.html || '' }}
        />
      </Article>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPage($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "D/M/Y")
        slug
        title
      }
    }
  }
`;

export default BlogPostTemplate;
