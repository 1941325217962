import styled from 'styled-components';
import { MAX_CONTENT_WIDTH } from '~/styles/layout';

export const Article = styled.article`
  max-width: ${MAX_CONTENT_WIDTH};
  padding: 32px 16px;
  margin: auto;

  caption {
    letter-spacing: 0.015em;
  }
`;
